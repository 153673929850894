import React, { Component } from "react";

class RowComponent extends Component {
    constructor(props) {
		super(props);
		this.state= {
            userCount: '',
            apiUrl: process.env.REACT_APP_API_URL
		}
        this.getUsersCount(this.props.centreId);
	}

    getUsersCount = async(userDetails) => {
        this.setState({ tableLoading: true });
        const centreId = userDetails;
        const requestType = 'centreUsersCount';
        fetch(`${this.state.apiUrl}/centre/listCentres.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                centreId,
                requestType
            })
        })
        .then(res => res.json())
        .then(json => {
            this.setState({
                userCount: json.data
            });
        })
    }
    render() {
    return (
    <React.Fragment>
        <div>
            {this.state.userCount}
        </div>
    </React.Fragment>
      );
    }
}
export default RowComponent;