import React, { Component } from "react";
import LeadDetailedExpandView from './LeadDetailedExpandView';

class ExpandedComponent extends Component {
    render() {
    return (
    <React.Fragment>
        <div>
            <LeadDetailedExpandView getListOfLeads={this.props.getListOfLeads} status={this.props.data} />
        </div>
    </React.Fragment>
      );
    }
}
export default ExpandedComponent;