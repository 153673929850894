import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */
//import AuthHelperMethods from '../../components/AuthHelperMethods';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
//import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
const dotenv = require("dotenv");
dotenv.config({ path: ".env" });


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
class onlineExcel extends Component {
    constructor(props) {
		super(props);
		this.state= {
            snackbaropen: false,
            userDetails: JSON.parse(localStorage.getItem('user')),
            //isLoaderOpen: true,
            iframeUrl1: 'https://docs.google.com/spreadsheets/d/1Luz5jQRqhNRtf_A942VBmJCwOr5yW1OnCDgvIrpORLw/edit#gid=0',
            iframeUrl2: 'https://docs.google.com/spreadsheets/d/1KiuS8NJKFIubdjzn1fTTvjSUQwo0dtO2kkdKRl1rPRk/edit#gid=1413032859',
            value:0,
            apiUrl: process.env.REACT_APP_API_URL
		}
	}


    handleIframeUrl1 = async(e)=>{
        this.setState({iframeUrl1: e.target.value})
    }
    handleIframeUrl2 = async(e)=>{
        this.setState({iframeUrl2: e.target.value})
    }

    handleChange = (event, newValue) => {
        this.setState({value: newValue});
      };

render() {
    const { userDetails, profileDetails, isLoaderOpen, updateBtn, value } = this.state;
    


return (
<React.Fragment>   
    <Backdrop className="backDropLoader" open={isLoaderOpen} >
        <CircularProgress color="inherit" />
    </Backdrop>
    <div id="profilePage">
          <Box sx={{ width: '100%' }}>
            <div>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={this.handleChange} aria-label="basic tabs example">
                  <Tab label="Areas" {...a11yProps(0)} />
                  <Tab label="Scheduling" {...a11yProps(1)} />
                </Tabs>
              </Box>
              
            </div>
            <TabPanel value={value} index={0}>
                <div style={{marginTop:'-19px',marginBottom:'6px'}}>
                    <TextField
                        type="text"
                        onBlur={this.handleIframeUrl1}
                        placeholder="URL"
                    />
                </div>
                <iframe src={this.state.iframeUrl1} width="100%" height="1000px" />
            </TabPanel>
            <TabPanel  value={value} index={1}>
                <div style={{marginTop:'-19px',marginBottom:'6px'}}>
                    <TextField
                        type="text"
                        onBlur={this.handleIframeUrl2}
                        placeholder="URL"
                    />
                </div>
                <iframe src={this.state.iframeUrl2} width="100%" height="1000px" />
            </TabPanel>
        </Box>

        
    </div>
</React.Fragment>
)
}

}export default onlineExcel;