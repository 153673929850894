import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */
import AuthHelperMethods from '../../components/AuthHelperMethods';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
const dotenv = require("dotenv");
dotenv.config({ path: ".env" });
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class statusConfiguration extends Component {
    Auth = new AuthHelperMethods();
    constructor(props) {
        super(props);
        this.state = {
            isLoaderOpen: true,
            profileDetails: JSON.parse(localStorage.getItem('user')),
            selectedFile: null,
            snackbaropen: false,
            alertMessage: '',
            apiUrl: process.env.REACT_APP_API_URL,
            apiKey: 'da731be431a0e7d95f01f98888ac94c1'
        }
        const userDetails = JSON.parse(localStorage.getItem('user'));
    }

    async componentDidMount() {
        this.setState({ isLoaderOpen: false })
    }
    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */



    _handleLogout = () => {
        this.Auth.logout()
        this.props.history.replace('/login');
    }



    changeHandler = (event) => {
        this.setState({ selectedFile: event.target.files[0] });
    };

    handleSubmission = async () => {
        console.log('FFF')
        this.setState({ isLoaderOpen: true });
        const formData = new FormData();
        formData.append('avatar', this.state.selectedFile)
        await axios.post(`${this.state.apiUrl}/leads/syncBulkStatus.php`, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(res => {
                if (res.status == 200) {
                    this.setState({
                        isLoaderOpen: false,
                        alertMessage: 'Status Synced Successfully!',
                        snackbaropen: true,
                        selectedFile: null
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });


    };

    render() {
        const { isLoaderOpen, selectedFile } = this.state;

        const bytesToSize = (byteVal) => {
            var units = ["Bytes", "KB", "MB", "GB", "TB"];
            var kounter = 0;
            var kb = 1024;
            var div = byteVal / 1;
            while (div >= kb) {
                kounter++;
                div = div / kb;
            }
            return div.toFixed(1) + " " + units[kounter];
        }




        return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={isLoaderOpen} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div id="statusConfigurationPage">
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={this.state.snackbaropen}
                        onClose={this.handleClose}
                        autoHideDuration={4000}
                        key={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={this.handleClose} severity="success">
                            {this.state.alertMessage}
                        </Alert>
                    </Snackbar>
                    <Breadcrumbs className="headerBreadcrumb" aria-label="breadcrumb">
                        <Typography color="textPrimary" >
                            Sync Bulk Status
                        </Typography>
                    </Breadcrumbs>
                    <div id="uploadBtn">
                        <input
                            style={{ display: "none" }}
                            id="contained-button-file"
                            type="file"
                            onChange={this.changeHandler}
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" color="primary" component="span">
                                Upload .xlsx
                            </Button>
                        </label>

                        {selectedFile ?
                            <div>
                                <div>
                                    <p>Filename: {selectedFile ? selectedFile.name : ''}</p>
                                    <p>Filetype: {selectedFile ? selectedFile.type : ''}</p>
                                    <p>Size in bytes: {selectedFile ? bytesToSize(selectedFile.size) : ''}</p>
                                </div>
                                <div>
                                    <Button variant="contained" color="secondary" onClick={this.handleSubmission}>Submit</Button>
                                </div>
                            </div> :
                            ''
                        }
                    </div>




                </div>
            </React.Fragment>
        );
    }

}

export default statusConfiguration;