import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SendIcon from '@material-ui/icons/Send';
import CircularProgress from '@material-ui/core/CircularProgress';
const dotenv = require("dotenv");
dotenv.config({ path: ".env" });

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
class LeadDetailedExpandView extends Component {
    leadNotesContainer = React.createRef();
    constructor(props) {
		super(props);
		this.state= {
			dataItems: [],
            snackbaropen: false,
            under80Year: false,
            isReceivedRebate: false,
            newLeadNotes: {},
            leadNotesList: [],
            logHistoryList: [],
            loaderVisible: 'none',
            editableFieldVisible: 'none',
            dateTimeDetVisible : 'grid',
            CancelEditFieldBtn: 'none',
            saveEditFieldBtn: 'none',
            EditFieldBtn: 'block',
            apptDate: this.props.status.appt_date,
            apptTime: this.props.status.appt_time,
            cusNameEdit: this.props.status.cus_name,
            cusEmailEdit: this.props.status.cus_email,
            cus_cityEdit: this.props.status.cus_city,
            cus_addressEdit: this.props.status.cus_address,
            lastNote: [],
            clipboardBtnName:'Click to copy Lead Details to Clipboard',
            profileDetails: JSON.parse(localStorage.getItem('user')),
            apiUrl: process.env.REACT_APP_API_URL
            
		}
	}
    componentDidMount = () => {
        console.log('--', this.props.status)
        this.setState({dataItems: this.props.status});
        this.fetchLeadNotes();
        this.fetchLeadLogs();
    }


    fetchLeadNotes = (e) => {
        this.setState({loaderVisible: 'block'});
        const userDetails = JSON.parse(localStorage.getItem('user'));
        const centreId = userDetails.centre_id;
        const lead_id = this.props.status.id
        const requestType = 'fetchNotes';
        fetch(`${this.state.apiUrl}/leads/leadNotes.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                centreId,lead_id,requestType
            })
        })
        .then(res => res.json())
        .then(json => {
            if(json.status == '200'){
                this.setState({
                    leadNotesList: json.data,
                    lastNote: json.lastNote[0],
                    loaderVisible: 'none'
                });
                this.autoScrollingLeadNotes()
            }else{
                this.setState({
                    loaderVisible: 'none'
                });
            }
        })
    }

    fetchLeadLogs = (e) => {
        this.setState({loaderVisible: 'block'});
        const userDetails = JSON.parse(localStorage.getItem('user'));
        const centreId = userDetails.centre_id;
        const lead_id = this.props.status.id
        const requestType = 'fetchLogHistory';
        fetch(`${this.state.apiUrl}/leads/leadsLog.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                centreId,lead_id,requestType
            })
        })
        .then(res => res.json())
        .then(json => {
            if(json.status == '200'){
                this.setState({
                    logHistoryList: json.data,
                    loaderVisible: 'none'
                });
                this.autoScrollingLeadNotes()
            }else{
                this.setState({
                    loaderVisible: 'none'
                });
            }
        })
    }


    

    handleQuestionLeadInput = (e) => {
        let val = '';
        if(e.target.value === ''){
            val = e.target.checked;
        }else{
            val = e.target.value;
        }
        if(e.target.name === 'is_80years' ){
            this.setState({ under80Year: e.target.checked })
        }else if(e.target.name === 'is_receivedRebate'){
            this.setState({ isReceivedRebate: e.target.checked })
        }
        var colName = e.target.name;
        var id = this.state.dataItems.id;
        var requestType = 'questionUpdate';
        fetch(`${this.state.apiUrl}/leads/modifyLeads.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                val,colName,id,requestType
            })
        })
        .then(res => {
            if(res.status == '200'){
                this.setState({
                    alertMessage: 'Lead is updated Successfully',
                    snackbaropen: true,
                });
            }
        })
    }
    
    NewLeadNote = (e) => {
        var val = e.target.value;
        var colName = e.target.name;
        var id = e.target.id;
        var obj = {};
        obj['val'] = val;
        obj['colName'] = colName;
        this.setState({ newLeadNotes: obj })
    }


    submitNewLeadNotes= (e) => {
        var datas = this.state.newLeadNotes
        const userDetails = JSON.parse(localStorage.getItem('user'));
        const agentName = userDetails.first_name + ' ' + userDetails.last_name;
        const userType = userDetails.user_type;
        const centreId = userDetails.centre_id;
        const leadId = this.props.status.id
        var requestType = 'leadNoteUpdate';
        fetch(`${this.state.apiUrl}/leads/leadNotes.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                datas, agentName, userType, centreId, leadId, requestType
            })
        })
        .then(res => {
            if(res.status == '200'){
                this.fetchLeadNotes();
                this.setState({
                    alertMessage: 'Lead note is updated Successfully',
                    snackbaropen: true,
                    newLeadNotes: {'empty': 'true'}
                });
            }
            console.log(this.state.newLeadNotes.empty === 'true')
        })
    }


    handleClose = () => {
        this.setState({snackbaropen: false});
    };

    //Auto Scroll lead Notes comments
    autoScrollingLeadNotes = () => {
        // varconst scroll =
        const scroll = this.leadNotesContainer.current.scrollHeight -
        this.leadNotesContainer.current.clientHeight;
        this.leadNotesContainer.current.scrollTo(0, scroll);
    };


    handleEditBtn = () => {
        this.setState({editableFieldVisible: 'block', dateTimeDetVisible: 'none', CancelEditFieldBtn: 'block', saveEditFieldBtn: 'block', EditFieldBtn: 'none'});
    }

    

    handleCancelEditFieldBtn = () => {
        this.setState({editableFieldVisible: 'none', dateTimeDetVisible: 'grid', saveEditFieldBtn: 'none', EditFieldBtn: 'block', CancelEditFieldBtn: 'none'});
    }

    handleSaveEditFieldBtn = (e) => {
        //cusNameEdit,cus_cityEdit,cus_addressEdi
        
        const cusName = this.state.cusNameEdit;
        const cusEmail = this.state.cusEmailEdit;
        const cusAddress = this.state.cus_addressEdit;
        const cusCity = this.state.cus_cityEdit;
        const apptNewDate = this.state.apptDate;
        const apptNewTime = this.state.apptTime;
        const leadId = e.target.id;
        var requestType = 'UpdateLeadDetails';
        fetch(`${this.state.apiUrl}/leads/modifyLeads.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                cusName,cusEmail,cusAddress,cusCity,apptNewDate,apptNewTime,leadId,requestType
            })
        })
        .then(res => {
            if(res.status == '200'){
                this.setState({
                    editableFieldVisible: 'none', 
                    dateTimeDetVisible: 'grid', 
                    saveEditFieldBtn: 'none', 
                    CancelEditFieldBtn: 'none', 
                    EditFieldBtn: 'block',
                    alertMessage: 'Lead is updated Successfully',
                    snackbaropen: true,
                });
                const userDetails = JSON.parse(localStorage.getItem('user'));
                this.props.getListOfLeads(userDetails)
                //localStorage.setItem('leadId', JSON.stringify(leadId));
            }
        })
        
    }

    handleOnchangeApptDate = (e) => {
        console.log(e.target.value);
        this.setState({apptDate: e.target.value})
    }

    handleOnchangeApptTime = (e) => {
        console.log(e.target.value);
        this.setState({apptTime: e.target.value})
    }


    
    handleClipboardCopy = (e) => { 
        
        navigator.clipboard.writeText(`
        "
        Spoke to  ${this.state.dataItems.cus_name} 
        the home owner to confirm the  appt. booked for ${this.state.apptDate} between  
        ${this.state.apptTime}
        
        Address
        ${this.state.dataItems.cus_address} 
        
        Descriptions:  ${this.state.lastNote.notes}
        Age limit (18-80): ${this.state.dataItems.is_80years === '1' || this.state.under80Year === true ? 'Yes' : 'No'}
        Furnace/AC: ${this.state.dataItems.ageOfFurnace}  
        Received any Rebate: ${this.state.dataItems.is_receivedRebate === '1' || this.state.isReceivedRebate === true ? 'Yes' : 'No'} 
        "
        `)
        this.setState({
            clipboardBtnName: 'Copied',
            alertMessage: 'Copied Successfully',
            snackbaropen: true,
        })
    }

    render() {
        const { dataItems, under80Year, isReceivedRebate, 
            leadNotesList, newLeadNotes, loaderVisible,
            editableFieldVisible, dateTimeDetVisible, CancelEditFieldBtn,
            saveEditFieldBtn, EditFieldBtn, lastNote,
            profileDetails,cusNameEdit,cusEmailEdit, cus_cityEdit, cus_addressEdit
        } = this.state;

        const IOSSwitch = withStyles((theme) => ({
            root: {
              width: 42,
              height: 26,
              padding: 0,
              margin: theme.spacing(1),
            },
            switchBase: {
              padding: 1,
              '&$checked': {
                transform: 'translateX(16px)',
                color: theme.palette.common.white,
                '& + $track': {
                  backgroundColor: '#52d869',
                  opacity: 1,
                  border: 'none',
                },
              },
              '&$focusVisible $thumb': {
                color: '#52d869',
                border: '6px solid #fff',
              },
            },
            thumb: {
              width: 24,
              height: 24,
            },
            track: {
              borderRadius: 26 / 2,
              border: `1px solid ${theme.palette.grey[400]}`,
              backgroundColor: theme.palette.grey[50],
              opacity: 1,
              transition: theme.transitions.create(['background-color', 'border']),
            },
            checked: {},
            focusVisible: {},
        }))(({ classes, ...props }) => {
            return (
              <Switch
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                classes={{
                  root: classes.root,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked,
                }}
                {...props}
              />
            );
            });


            
      return (
        <React.Fragment>
            <div>
            <Snackbar
                anchorOrigin={{  vertical: 'top', horizontal: 'right' }}
                open={this.state.snackbaropen}
                onClose={this.handleClose}
                autoHideDuration={4000}
                key={{vertical: 'top', horizontal: 'right'}}
            >
                <Alert onClose={this.handleClose} severity="success">
                    {this.state.alertMessage}
                </Alert>
            </Snackbar>
            <div id="leadExpandView">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={2}>
                        <div class="leadViewDetailsLeftBlock">
                            <div class="leadViewDetails" style={{display: dateTimeDetVisible}}>
                                <label>Appointment Date Time:</label> 
                                <span>{this.state.apptDate} ({this.state.apptTime})</span>
                            </div>
                            <div class="leadViewDetails" style={{display: dateTimeDetVisible}}>
                                <label>Customer Name:</label> 
                                <span>{dataItems.cus_name}</span>
                            </div>
                            <div class="leadViewDetails" style={{display: dateTimeDetVisible}}>
                                <label>Customer Address:</label> 
                                <span>{dataItems.cus_address === ''? '-' : dataItems.cus_address}</span>
                            </div>
                            <div class="leadViewDetails" style={{display: dateTimeDetVisible}}>
                                <label>City:</label> 
                                <span>{dataItems.cus_city === ''? '-' : dataItems.cus_city}</span>
                            </div>
                            <div style={{display: editableFieldVisible}}>
                                <div class="leadViewDetails" >
                                    <label>Appointment Date Time:</label> 
                                    <span style={{marginBottom: '1%',marginTop: '4%'}}>
                                        <TextField 
                                            label="Appointment Date"
                                            type="date" 
                                            variant="outlined"
                                            id="apptDateVal"
                                            onChange={this.handleOnchangeApptDate} 
                                            value={this.state.apptDate}
                                            fullWidth={true} 
                                        />
                                    </span>
                                    <span style={{marginBottom: '1%',marginTop: '4%'}}>
                                        <TextField
                                            id="outlined-select-currency-native"
                                            name="appt_time"
                                            select
                                            label="Appointment Time"
                                            onChange={this.handleOnchangeApptTime} 
                                            defaultValue={dataItems.appt_time}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            variant="outlined"
                                            fullWidth={true} 
                                            required
                                        >
                                            <option key={dataItems.appt_time} value={dataItems.appt_time} selected disabled>{dataItems.appt_time}</option>
                                            <option key="9am - 11am" value="9am - 11am">9am - 11am</option>
                                            <option key="10am - 12am" value="10am - 12am">10am - 12am</option>
                                            <option key="11am - 1pm" value="11am - 1pm">11am - 1pm</option>
                                            <option key="12am - 2pm" value="12am - 2pm">12am - 2pm</option>
                                            <option key="1pm - 3pm" value="1pm - 3pm">1pm - 3pm</option>
                                            <option key="2pm - 4pm" value="2pm - 4pm">2pm - 4pm</option>
                                            <option key="3pm - 5pm" value="3pm - 5pm">3pm - 5pm</option>
                                            <option key="4pm - 6pm" value="4pm - 6pm">4pm - 6pm</option>
                                            <option key="5pm - 7pm" value="5pm - 7pm">5pm - 7pm</option>
                                            <option key="6pm - 8pm" value="6pm - 8pm">6pm - 8pm</option>
                                            <option key="7pm - 9pm" value="7pm - 9pm">7pm - 9pm</option>
                                        </TextField>
                                    </span>
                                    <span>
                                        <TextField name="cus_name" onBlur={(e)=> this.setState({cusNameEdit: e.target.value})} defaultValue={cusNameEdit} fullWidth={true} label="Customer Name" variant="outlined" style={{marginBottom: '1%',marginTop: '4%'}} />
                                        <TextField name="cus_email" onBlur={(e)=> this.setState({cusEmailEdit: e.target.value})} defaultValue={cusEmailEdit} fullWidth={true} label="Email" variant="outlined" style={{marginBottom: '1%',marginTop: '4%'}} />
                                        <TextField name="cus_address" onBlur={(e)=> this.setState({cus_addressEdit: e.target.value})} multiline defaultValue={cus_addressEdit} fullWidth={true} label="Customer Address" variant="outlined" style={{marginBottom: '1%',marginTop: '4%'}} />
                                        <TextField name="cus_city" onBlur={(e)=> this.setState({cus_cityEdit: e.target.value})} defaultValue={cus_cityEdit} fullWidth={true} label="City" variant="outlined" style={{marginBottom: '1%',marginTop: '4%'}} />
                                    </span>
                                </div>
                            </div>
                            {/* <div class="leadViewDetails">
                                <label>Lead Status:</label> 
                                <span>{dataItems.lead_status}</span>
                            </div> */}
                            {profileDetails.user_type === 'callback'? '' :
                            <button 
                                className="clipboardCopyBtn"
                                onClick={this.handleClipboardCopy }
                            >
                                {this.state.clipboardBtnName}
                            </button>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <div class="leadViewDetailsRightBlock">
                            <div class="leadViewDetails">
                                <label>Lead Type:</label> 
                                <span>{dataItems.lead_type}</span>
                            </div>
                            <div class="leadViewDetails">
                                <label>Customer phone:</label> 
                                <span>{dataItems.cus_phone === ''? '-' : dataItems.cus_phone}</span>
                            </div>
                            <div class="leadViewDetails" style={{display: editableFieldVisible}}>
                                <TextField
                                    id="outlined-select-currency-native"
                                    name="appt_time"
                                    select
                                    label="Customer Gender"
                                    //onChange={this.handleOnchangeApptTime} 
                                    defaultValue={dataItems.gender}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="outlined"
                                    fullWidth={true} 
                                    required
                                >
                                    <option key={dataItems.gender} value={dataItems.gender} selected disabled>{dataItems.gender}</option>
                                    <option key="Male" value="Male">Male</option>
                                    <option key="Femalem" value="Femalem">Femalem</option>
                                </TextField>
                            </div>
                            <div class="leadViewDetails">
                                <label>Customer Gender:</label> 
                                <span>{dataItems.gender ? dataItems.gender : '-'}</span>
                            </div>
                            <div class="leadViewDetails">
                                <label>Lead Notes:</label> 
                                <span>{dataItems.lead_notes === ''? '-' : dataItems.lead_notes}</span>
                            </div>
                        </div>
                        <div className="tableEditBtns" >
                            <button className="editBtn" style={{display:EditFieldBtn}} onClick={this.handleEditBtn}>Edit</button>
                            <button className="updateBtn" id={dataItems.id} style={{display:saveEditFieldBtn}} onClick={this.handleSaveEditFieldBtn}>Update</button>
                            <button className="cancelBtn" style={{display:CancelEditFieldBtn}} onClick={this.handleCancelEditFieldBtn}>Cancel</button>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <div class="leadViewDetailsQuestionBlock">
                            <div class="leadViewDetailsQuestion">
                                <label class="title">Is under 80 years? ({dataItems.is_80years === '1' || under80Year === true ? 'Yes' : 'No'})</label>
                                <FormControlLabel
                                    size="small"
                                    control={<IOSSwitch defaultChecked={dataItems.is_80years === '1' || under80Year === true ? true : false} onChange={this.handleQuestionLeadInput} id={dataItems.lead_id} name="is_80years" />}
                                />
                            </div>
                            <div class="leadViewDetailsQuestion">
                                <label class="title">Have you Received Rebate? ({dataItems.is_receivedRebate === '1' || isReceivedRebate === true ? 'Yes' : 'No'})</label>
                                <FormControlLabel
                                    size="small"
                                    control={<IOSSwitch defaultChecked={dataItems.is_receivedRebate === '1' || isReceivedRebate === true ? true : false} onChange={this.handleQuestionLeadInput} id={dataItems.lead_id} name="is_receivedRebate" />}
                                />
                            </div>
                            <div class="leadViewDetailsQuestion">
                                <label class="title">Age of Furnace/AC?  ( {dataItems.ageOfFurnace} )</label>
                                <TextField
                                    onBlur={this.handleQuestionLeadInput}
                                    name="ageOfFurnace"
                                    type="number"
                                    label="Age of Furnace/AC?"
                                    fullWidth={true}
                                    // inputProps={{
                                    //     value:dataItems.ageOfFurnace
                                    // }}
                                    variant="outlined"
                                    //value={dataItems.ageOfFurnace}
                                    
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={3} >
                        <div class="leadStatusSection" >
                            <div style={{display:'flex'}}>
                                <div class="notesHeader" style={{width: '50%'}}>Callback Notes</div>
                                <div style={{width: '50%',textAlign: 'right',fontSize: '13px'}}></div>
                            </div>
                            <div class="leadStatusBlock">
                                <div class="previousNotesBlock" ref={this.leadNotesContainer}>
                                <CircularProgress style={{display: loaderVisible}} />
                                    {leadNotesList.map(i => (
                                        <div class="leadNotesVIew">
                                            <div class="title">{i.agent_name}</div>
                                            <div class="updatedOn">{i.datetime}</div>
                                            <div class="notes">{i.notes}</div>
                                        </div>
                                    ))}
                                </div>
                                <div class="leadViewDetailsQuestion" style={{display: 'flex'}}>
                                    <TextField
                                        onBlur={this.NewLeadNote}
                                        name="newLeadNote"
                                        label={`Enter latest status for ${dataItems.cus_name}`}
                                        fullWidth={true}
                                        inputProps={{
                                            disabled: newLeadNotes.empty === 'true' ? false : false
                                          }}
                                        value={newLeadNotes.empty === 'true' ? '' : newLeadNotes.newLeadNote}
                                    />
                                    <SendIcon onClick={this.submitNewLeadNotes} fontSize="small" />
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={3} >
                        <div class="leadStatusSection" >
                            <div style={{display:'flex'}}>
                                <div class="notesHeader" style={{width: '50%'}}>Log History</div>
                                <div style={{width: '50%',textAlign: 'right',fontSize: '13px'}}></div>
                            </div>
                            <div class="leadStatusBlock">
                                <div class="previousNotesBlock" ref={this.leadNotesContainer} style={{maxHeight:'306px'}}>
                                    <CircularProgress style={{display: loaderVisible}} />
                                    {this.state.logHistoryList && this.state.logHistoryList.length > 0 ? this.state.logHistoryList.map(i => (
                                        <div class="leadNotesVIew">
                                            <div class="title" style={{color:'#3d6f87'}}>{i.action}</div>
                                            <div class="updatedOn" style={{color:'#767676'}}>{i.created_by} | {i.created_at}</div>
                                            <div class="notes">{i.log_status}</div>
                                        </div>
                                    )) : <div style={{textAlign:'center'}}>No Logs Found</div>
                                }
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                </div>
            </div>
        </React.Fragment>
      );
    }
}
export default LeadDetailedExpandView;