import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */
//import AuthHelperMethods from '../../components/AuthHelperMethods';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
//import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DataTable from 'react-data-table-component';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import ExpandedComponent from './ExpandedComponent';
import exportFromJSON from 'export-from-json'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import CallIcon from '@material-ui/icons/Call';
import { Select } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import MenuItem from '@mui/material/MenuItem';
import { Menu, IconButton, } from '@mui/material';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
const dotenv = require("dotenv");
dotenv.config({ path: ".env" });


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class Leads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            leadData: [],
            drawerOpen: false,
            snackbaropen: false,
            alertMessage: '',
            filterFieldDatas: JSON.parse(localStorage.getItem('filterFieldDatas')),
            addLeadDatas: {},
            //tableLoading: true,
            addLeadModal: false,
            btnLoading: false,
            recordingModal: false,
            recordingDet: [],
            recordingUrl: '',
            profileDetails: JSON.parse(localStorage.getItem('user')),
            autoLoadTable: false,
            clientList: [],
            isLoaderOpen: true,
            statusActionIsOpen: false,
            statusActionPosition: null,
            selectedRows: [],
            apiUrl: process.env.REACT_APP_API_URL
        }
        const userDetails = JSON.parse(localStorage.getItem('user'));
        const filterSessionDet = JSON.parse(localStorage.getItem('filterFieldDatas'));
        //this.getListOfLeads(userDetails)
        if (filterSessionDet === null || filterSessionDet === 'null') {
            this.getListOfLeads(userDetails)
        } else {
            this.handleFiltersLoadAuto()
        }
        this.getListOfClients(userDetails)
        //this.getListOfLeads = this.getListOfLeads.bind(this);

        this.interval =
            setInterval(() => {
                if (this.state.autoLoadTable === true) {
                    const userDetails = JSON.parse(localStorage.getItem('user'));
                    this.handleFiltersLoadAuto()
                    console.log('now0');
                }
            }, !localStorage.getItem('autoLoadIntervalTime') || JSON.parse(localStorage.getItem('autoLoadIntervalTime')) === '' ? 120000 : JSON.parse(localStorage.getItem('autoLoadIntervalTime')));

    }

    async componentDidMount() {

    }

    clearFilters = () => {
        this.setState({ filterFieldDatas: {} })
        const userDetails = JSON.parse(localStorage.getItem('user'));
        this.getListOfLeads(userDetails);
        localStorage.setItem('filterFieldDatas', JSON.stringify(''));
    }


    getListOfClients = async (userDetails) => {
        const centreId = userDetails.centre_id;
        const requestType = 'listOfClients';
        fetch(`${this.state.apiUrl}/client/listClients.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                centreId, requestType
            })
        })
            .then(res => res.json())
            .then(json => {
                if (json.status === 200) {
                    this.setState({
                        clientList: json.data,
                    });
                } else {
                    this.setState({
                        clientList: [],
                    });
                }

            })
    }


    getListOfRecordings = (e) => {
        this.setState({ recordingDet: [], recordingUrl: '', isLoaderOpen: true });
        const agentId = e.target.attributes.agentId.value;
        const date = e.target.attributes.createdDate.value;
        const leadId = e.target.attributes.leadId.value;
        const requestType = 'fetchRecording';
        fetch(`${this.state.apiUrl}/vicidial/fetchRecording.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                agentId,
                date,
                leadId,
                requestType
            })
        })
            .then(res => res.json())
            .then(json => {
                var data = json.data;
                var splitted = data.split("\n");
                let arr = [];
                for (var n = 0; n < splitted.length; n++) {
                    arr.push(splitted[n]);
                }
                console.log(arr[0])
                var res1 = arr[0].split("|");
                let res = [];
                for (var n = 0; n < res1.length; n++) {
                    res.push(res1[n]);
                }
                var res2 = res[4].replace("38.91.101.252", "web.24sevensupportservices.com");
                console.log(res2)
                this.setState({ recordingModal: true, recordingDet: res, recordingUrl: res2, isLoaderOpen: false })
            })
    }

    handleFiltersLoadAuto = () => {
        localStorage.setItem('filterFieldDatas', JSON.stringify(this.state.filterFieldDatas));
        this.setState({ isLoaderOpen: true });
        const userDetails = JSON.parse(localStorage.getItem('user'));
        let filterFieldData = JSON.parse(localStorage.getItem('filterFieldDatas'));
        const centreId = userDetails.centre_id;
        let searchFromDate = '';
        let searchToDate = '';
        let searchPhone = '';
        let agent_number = '';
        let center_name = '';
        let cus_name = '';
        let appt_time = '';
        let lead_status = '';
        let client_name = '';
        let lead_type = '';

        if ((this.state.filterFieldDatas) && Object.keys(this.state.filterFieldDatas).length != 0) {
            searchFromDate = filterFieldData.from_date;
            searchToDate = filterFieldData.to_date;
            searchPhone = filterFieldData.phone_no;
            agent_number = filterFieldData.agent_no;
            center_name = filterFieldData.center_name;
            cus_name = filterFieldData.customer_name;
            appt_time = filterFieldData.appt_time;
            lead_status = filterFieldData.lead_status;
            client_name = filterFieldData.client_name;
            lead_type = filterFieldData.lead_type;

        } else {
            searchFromDate = '';
            searchToDate = '';
            searchPhone = '';
            agent_number = '';
            center_name = '';
            cus_name = '';
            appt_time = '';
            lead_status = '';
            client_name = '';
            lead_type = '';
        }
        const requestType = 'listOfLeads';
        fetch(`${this.state.apiUrl}/leads/listofLeads.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                centreId,
                searchFromDate,
                searchToDate,
                searchPhone,
                agent_number,
                center_name,
                cus_name,
                appt_time,
                lead_status,
                client_name,
                lead_type,
                requestType
            })
        })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    leadData: json.data,
                    isLoaderOpen: false,
                    drawerOpen: false,
                    alertMessage: 'Lead is filtered successfully!',
                    //snackbaropen: true,
                    //filterFieldDatas: {} 
                    //localStorage.setItem('filterFieldDatas', JSON.stringify(this.state.filterFieldDatas));
                });
            })
    }

    handleFilters = (e) => {
        e.preventDefault();
        localStorage.setItem('filterFieldDatas', JSON.stringify(this.state.filterFieldDatas));

        this.setState({ isLoaderOpen: true });
        const userDetails = JSON.parse(localStorage.getItem('user'));

        let filterFieldData = JSON.parse(localStorage.getItem('filterFieldDatas'));
        const centreId = userDetails.centre_id;
        let searchFromDate = '';
        let searchToDate = '';
        let searchPhone = '';
        let agent_number = '';
        let center_name = '';
        let cus_name = '';
        let appt_time = '';
        let lead_status = '';
        let client_name = '';
        let lead_type = '';

        if (Object.keys(this.state.filterFieldDatas).length != 0) {
            searchFromDate = filterFieldData.from_date;
            searchToDate = filterFieldData.to_date;
            searchPhone = filterFieldData.phone_no;
            agent_number = filterFieldData.agent_no;
            center_name = filterFieldData.center_name;
            cus_name = filterFieldData.customer_name;
            appt_time = filterFieldData.appt_time;
            lead_status = filterFieldData.lead_status;
            client_name = filterFieldData.client_name;
            lead_type = filterFieldData.lead_type;

        } else {
            searchFromDate = '';
            searchToDate = '';
            searchPhone = '';
            agent_number = '';
            center_name = '';
            cus_name = '';
            appt_time = '';
            lead_status = '';
            client_name = '';
            lead_type = '';
        }
        const requestType = 'listOfLeads';
        fetch(`${this.state.apiUrl}/leads/listofLeads.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                centreId,
                searchFromDate,
                searchToDate,
                searchPhone,
                agent_number,
                center_name,
                cus_name,
                appt_time,
                lead_status,
                client_name,
                lead_type,
                requestType
            })
        })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    leadData: json.data,
                    isLoaderOpen: false,
                    drawerOpen: false,
                    alertMessage: 'Lead is filtered successfully!',
                    snackbaropen: true,
                    //filterFieldDatas: {} 
                });
            })
    }

    getListOfLeads = async (userDetails) => {
        this.setState({ isLoaderOpen: true });
        const centreId = userDetails.centre_id;
        const requestType = 'listOfLeads';
        fetch(`${this.state.apiUrl}/leads/listofLeads.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                centreId, requestType
            })
        })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    leadData: json.data,
                    isLoaderOpen: false,
                    alertMessage: 'Lead data is loaded successfully!',
                    snackbaropen: true
                });
            })
    }


    tableSearchInput = (e) => {
        let currValue = e.target.value;
        if (currValue === '' || currValue === null) {
            const userDetails = JSON.parse(localStorage.getItem('user'));
            this.getListOfLeads(userDetails)
        } else {
            let leadDatas = this.state.leadData;
            const filterTable = leadDatas.filter(o =>
                Object.keys(o).some(k =>
                    String(o[k])
                        .toLowerCase()
                        .includes(currValue.toLowerCase())
                )
            );
            this.setState({ leadData: filterTable });
        }
    }

    handleInputValue = (e) => {
        console.log('id', e.target.id);
        if (e.target.id === 'lead_type') {
            const { options } = e.target;
            const value = [];
            for (let i = 0, l = options.length; i < l; i += 1) {
                if (options[i].selected) {
                    value.push(options[i].value);
                }
            }
            console.log(value); // selected options
            var idaa = e.target.id;
            var obj = { ...this.state.filterFieldDatas };
            obj[idaa] = value;
            this.setState({ filterFieldDatas: obj })
        } else {
            let val = e.target.value;
            var idaa = e.target.id;
            var obj = { ...this.state.filterFieldDatas };
            obj[idaa] = val;
            this.setState({ filterFieldDatas: obj })
        }

    }

    handleLeadTypeOnChange = (event) => {

    }

    viewLeadInfo = (e) => {
        console.log(e.target.parentNode.parentNode.parentNode.querySelector('.exandClick'));
    }

    handleAddLeadInput = (e) => {
        let val = e.target.value;
        var name = e.target.name;
        var obj = { ...this.state.addLeadDatas };
        obj[name] = val;
        this.setState({ addLeadDatas: obj })
    }




    handleAddLeadClose = () => {
        this.setState({ addLeadModal: false })
    }

    handleClose = () => {
        this.setState({ snackbaropen: false });
    };

    submitLead = event => {
        event.preventDefault();
        this.setState({ btnLoading: true });
        const userDetails = JSON.parse(localStorage.getItem('user'));
        const agent_name_id = userDetails.first_name + '' + userDetails.last_name
        const centreId = userDetails.centre_id;
        const addedLeadsInputValues = this.state.addLeadDatas;
        const requestType = 'addLeads';
        fetch(`${this.state.apiUrl}/leads/addLeads.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                addedLeadsInputValues,
                agent_name_id,
                centreId,
                requestType
            })
        })
            .then(res => {
                console.log(res);
                console.log(res.status);
                if (res.status == '200') {
                    this.setState({
                        addLeadModal: false,
                        btnLoading: false
                    });
                    //const userDetails = JSON.parse(localStorage.getItem('user'));
                    this.handleFiltersLoadAuto();
                }
            })
    };

    handleClientNameChange = (e) => {
        this.setState({ isLoaderOpen: true });
        const leadId = e.target.name;
        const clientName = e.target.value;
        const requestType = 'updateClientName';
        fetch(`${this.state.apiUrl}/leads/modifyLeads.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                requestType,
                clientName,
                leadId
            })
        })
            .then(res => {
                console.log(res);
                console.log(res.status);
                if (res.status == '200') {
                    this.handleFiltersLoadAuto();
                }
            })
    }

    handleLeadTypeChange = (e) => {
        this.setState({ isLoaderOpen: true });
        const leadId = e.target.name;
        const leadType = e.target.value;
        const createdBy = `${this.state.profileDetails.first_name} ${this.state.profileDetails.last_name}`;
        const requestType = 'updateLeadType';
        fetch(`${this.state.apiUrl}/leads/modifyLeads.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                requestType,
                leadType,
                leadId,
                createdBy
            })
        })
            .then(res => {
                console.log(res);
                console.log(res.status);
                if (res.status == '200') {
                    this.handleFiltersLoadAuto();
                }
            })
    }

    handleStatusChange = (e) => {
        this.setState({ isLoaderOpen: true });
        const leadId = e.target.name;
        const status = e.target.value;
        const createdBy = `${this.state.profileDetails.first_name} ${this.state.profileDetails.last_name}`;
        const requestType = 'updateLeadStatus';
        fetch(`${this.state.apiUrl}/leads/modifyLeads.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                requestType,
                status,
                leadId,
                createdBy
            })
        })
            .then(res => {
                console.log(res);
                console.log(res.status);
                if (res.status == '200') {
                    this.handleFiltersLoadAuto();
                }
            })
    }


    handleIsConfirmStatus = (e) => {
        this.setState({ isLoaderOpen: true });
        const leadId = e.target.name;
        const isConfirm = e.target.checked;
        const createdBy = `${this.state.profileDetails.first_name} ${this.state.profileDetails.last_name}`;
        const requestType = 'updateIsConfirmStatus';
        fetch(`${this.state.apiUrl}/leads/modifyLeads.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                requestType,
                isConfirm,
                leadId,
                createdBy
            })
        })
            .then(res => {
                console.log(res);
                console.log(res.status);
                if (res.status == '200') {
                    this.handleFiltersLoadAuto();
                }
            })
    }


    ExportToExcel = () => {
        const today = new Date(),
            date = today.getMonth() + '-' + (today.getDate() + 1) + '-' + today.getFullYear();

        const data = this.state.leadData;
        const fileName = `list_of_Leads_${date}`;
        const exportType = 'xls';
        exportFromJSON({ data, fileName, exportType })
    }

    handleAutoLoadTable = (e) => {
        const checkedVal = e.target.checked;
        console.log(checkedVal)
        if (checkedVal === true) {
            this.setState({ autoLoadTable: true })
        } else {
            this.setState({ autoLoadTable: false })
        }
    }


    handleChangeCityColor = (e) => {
        this.setState({ isLoaderOpen: true });
        const colorVal = e.target.value;
        const leadId = e.target.name;
        const requestType = 'updateLeadCityColor';
        fetch(`${this.state.apiUrl}/leads/modifyLeads.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                requestType,
                colorVal,
                leadId
            })
        })
            .then(res => {
                if (res.status == '200') {
                    this.setState({ isLoaderOpen: false });
                    this.handleFiltersLoadAuto();
                }
            })

    }

    setTableLoadIntervalTime = (e) => {
        localStorage.setItem('autoLoadIntervalTime', e.target.value);
        // this.setState({autoLoadIntervalTime: e.target.value})

    }

    setIntervalTimeBtn = (e) => {
        window.location.reload();
    }


    updateStatusRowLevel = async (event) => {
        this.setState({ isLoaderOpen: true });
        const leadIds = this.state.selectedRows;
        const status = event.target.innerText;
        const requestType = 'updateRowLevelStatus';
        fetch(`${this.state.apiUrl}/leads/modifyLeads.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                requestType,
                status,
                leadIds
            })
        })
            .then(res => {
                console.log(res);
                console.log(res.status);
                if (res.status == '200') {
                    const userDetails = JSON.parse(localStorage.getItem('user'));
                    const filterSessionDet = JSON.parse(localStorage.getItem('filterFieldDatas'));
                    if (filterSessionDet === null || filterSessionDet === 'null') {
                        this.getListOfLeads(userDetails)
                    } else {
                        this.handleFiltersLoadAuto()
                    }
                }
            })
    }

    render() {
        const { profileDetails, isLoaderOpen } = this.state;

        const IOSSwitch = withStyles((theme) => ({
            root: {
                width: 42,
                height: 26,
                padding: 0,
                margin: theme.spacing(1),
            },
            switchBase: {
                padding: 1,
                '&$checked': {
                    transform: 'translateX(16px)',
                    color: theme.palette.common.white,
                    '& + $track': {
                        backgroundColor: '#52d869',
                        opacity: 1,
                        border: 'none',
                    },
                },
                '&$focusVisible $thumb': {
                    color: '#52d869',
                    border: '6px solid #fff',
                },
            },
            thumb: {
                width: 24,
                height: 24,
            },
            track: {
                borderRadius: 26 / 2,
                border: `1px solid ${theme.palette.grey[400]}`,
                backgroundColor: theme.palette.grey[50],
                opacity: 1,
                transition: theme.transitions.create(['background-color', 'border']),
            },
            checked: {},
            focusVisible: {},
        }))(({ classes, ...props }) => {
            return (
                <Switch
                    focusVisibleClassName={classes.focusVisible}
                    disableRipple
                    classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked,
                    }}
                    {...props}
                />
            );
        });

        const omitStatus = profileDetails.user_type === 'agent' ? true : false;
        const omitIsConfirm = profileDetails.user_type === 'agent' ? true : false;
        const omitRecording = profileDetails.user_type === 'superadmin' ? false : true;
        const omitClinetView = profileDetails.user_type === 'superadmin' || (profileDetails.centre_id === 'superadmin' && profileDetails.user_type === 'manager') ? false : true;
        const omitColorView = profileDetails.user_type === 'superadmin' || (profileDetails.centre_id === 'superadmin' && profileDetails.user_type === 'manager') || (profileDetails.centre_id === 'superadmin' && profileDetails.user_type === 'callback') ? false : true;
        const omitMakeCall = profileDetails.user_type === 'callback' || profileDetails.user_type === 'superadmin' ? false : true;
        const omitLeadType = profileDetails.user_type === 'callback' || profileDetails.user_type === 'superadmin' || profileDetails.user_type === 'manager' ? false : true;


        const columns = [
            {
                name: 'Appoinment Date & Time',
                //selector: 'appt_date',
                sortable: true,
                width: '13%',
                cell: row =>
                    <div data-tag="allowRowEvents">
                        <div style={{ fontWeight: '500', color: '#e91e63' }}>{row.appt_date} ({row.appt_time})</div>
                    </div>,
            },
            {
                name: 'Agent ID',
                selector: 'vici_agent_id',
                sortable: true,
                width: '7%',
            },
            {
                name: 'Lead Type',
                selector: 'lead_type',
                sortable: true,
                omit: omitLeadType,
                cell: row =>
                    <TextField
                        id="outlined-select-currency-native"
                        name={row.id}
                        onChange={this.handleLeadTypeChange}
                        select
                        //label="Lead Status"
                        defaultValue={row.lead_type}
                        SelectProps={{
                            native: true,
                        }}
                        fullWidth={true}
                        required
                    >
                        <option key="" value="" disabled></option>
                        {/* <option key='Attic Insulation' value="Attic Insulation">Attic Insulation</option> */}
                        {/* <option key='HVAC' value="HVAC">HVAC</option> */}
                        <option key='Virtual' value="Virtual">Virtual</option>
                        <option key='Helium' value="Helium">Helium</option>

                        <option key='Rebate' value="Rebate">Rebate</option>
                        <option key='Check UP' value="Check UP">Check UP</option>
                        <option key='Elderly' value="Elderly">Elderly</option>

                    </TextField>
            },
            {
                name: 'Customer Name',
                //selector: 'cus_name',
                sortable: true,
                cell: row =>
                    <span>{profileDetails.user_type === 'agent' || profileDetails.user_type === 'tl' ? '-' : row.cus_name}</span>,
            },
            {
                name: 'Customer Address',
                selector: 'cus_address',
                sortable: true,
                cell: row =>
                    <span>{profileDetails.user_type === 'agent' || profileDetails.user_type === 'tl' ? '-' : row.cus_address}</span>,

            },
            {
                name: 'City',
                selector: 'cus_city',
                sortable: true,
                width: '10%',
                center: true,
                cell: row =>
                    <div className="cityColorChange" style={{ background: row.cityColor === '' ? 'transparent' : row.cityColor, color: row.cityColor === '' ? 'black' : 'white', padding: '8px 8px', borderRadius: '7px', textAlign: 'center', width: '100%' }}>
                        <span>{profileDetails.user_type === 'agent' || profileDetails.user_type === 'tl' ? '-' : row.cus_city}</span>
                    </div>

            },
            {
                name: 'Phone',
                selector: 'cus_phone',
                sortable: true,
                cell: row =>
                    <span>{profileDetails.user_type === 'agent' || profileDetails.user_type === 'tl' ? '-' : row.cus_phone}</span>,
            },
            {
                name: 'Status',
                //selector: 'lead_status',
                sortable: true,
                center: true,
                omit: omitStatus,
                width: '8%',
                cell: row =>
                    <div data-tag="allowRowEvents" style={{ padding: '6px', background: row.lead_status === 'Confirmed' ? '#8bc34a' : row.lead_status === 'Cancel-callback' ? 'red' : row.lead_status === 'Cancel' ? 'red' : row.lead_status === 'Duplicate' ? 'red' : row.lead_status === 'DNC' ? 'red' : row.lead_status === 'Scheduled' ? '#ffc107' : 'transparent' }}>
                        {profileDetails.user_type === 'callback' ?
                            <TextField
                                id={row.lead_status === 'Confirmed' || row.lead_status === 'Cancel-callback' || row.lead_status === 'Cancel' || row.lead_status === 'Duplicate' || row.lead_status === 'DNC' || row.lead_status === 'Scheduled' ? 'whileFont' : ''}
                                name={row.id}
                                onChange={this.handleStatusChange}
                                select
                                //label="Lead Status"
                                defaultValue={row.lead_status}
                                SelectProps={{
                                    native: true,
                                }}
                                fullWidth={true}
                                required
                            >
                                {/* <option key="Not Confirmed" value="Not Confirmed">Not Confirmed</option> */}
                                <option key="Confirmed" value="Confirmed">Confirmed</option>
                                <option key="Cancel-callback" value="Cancel">Cancel-callback</option>
                                <option key="NA/VM - callback" value="NA/VM - callback">NA/VM - callback</option>
                                <option key="DNC" value="DNC">DNC</option>
                                <option key="Duplicate" value="Duplicate">Duplicate</option>
                                <option key="CB - Scheduled" value="CB - Scheduled">CB - Scheduled</option>
                                <option key="CB - Confirmed" value="CB - Confirmed">CB - Confirmed</option>
                                <option key="QC - Confirmed" value="QC - Confirmed">QC - Confirmed</option>
                                <option key="To be Rescheduled" value="To be Rescheduled">To be Rescheduled</option>
                                <option key="Over 80" value="Over 80">Over 80</option>
                            </TextField> :
                            profileDetails.user_type === 'tl' ?
                                row.lead_status === 'QC - Confirmed' || row.lead_status === 'CB - Confirmed' ? '-' : row.lead_status :
                                profileDetails.centre_id === 'superadmin' && profileDetails.user_type === 'manager' ?
                                    <TextField
                                        id={row.lead_status === 'Confirmed' || row.lead_status === 'Cancel-callback' || row.lead_status === 'Cancel' || row.lead_status === 'Duplicate' || row.lead_status === 'DNC' || row.lead_status === 'Scheduled' ? 'whileFont' : ''}
                                        name={row.id}
                                        onChange={this.handleStatusChange}
                                        select
                                        //label="Lead Status"
                                        defaultValue={row.lead_status}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        fullWidth={true}
                                        required
                                    >
                                        <option key="Not Confirmed" value="Not Confirmed">Not Confirmed</option>
                                        <option key="Confirmed" value="Confirmed">Confirmed</option>
                                        <option key="Scheduled" value="Scheduled">Scheduled</option>
                                        <option key="Cancel-callback" value="Cancel">Cancel-callback</option>
                                        <option key="Customer Cancelled" value="Customer Cancelled">Customer Cancelled</option>
                                        <option key="Cancel" value="Cancel">Cancel</option>
                                        <option key="NA/VM - callback" value="NA/VM - callback">NA/VM - callback</option>
                                        <option key="DNC" value="DNC">DNC</option>
                                        <option key="Duplicate" value="Duplicate">Duplicate</option>
                                        <option key="QC - Confirmed" value="QC - Confirmed">QC - Confirmed</option>
                                        <option key="CB - Confirmed" value="CB - Confirmed">CB - Confirmed</option>
                                        <option key="Over 80" value="Over 80">Over 80</option>
                                    </TextField>
                                    :
                                    <TextField
                                        id={row.lead_status === 'Confirmed' || row.lead_status === 'Cancel-callback' || row.lead_status === 'Cancel' || row.lead_status === 'Duplicate' || row.lead_status === 'DNC' || row.lead_status === 'Scheduled' ? 'whileFont' : ''}
                                        name={row.id}
                                        onChange={this.handleStatusChange}
                                        select
                                        //label="Lead Status"
                                        defaultValue={row.lead_status}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        fullWidth={true}
                                        required
                                    >
                                        <option key="Not Confirmed" value="Not Confirmed">Not Confirmed</option>
                                        <option key="Confirmed" value="Confirmed">Confirmed</option>
                                        <option key="Scheduled" value="Scheduled">Scheduled</option>
                                        <option key="Cancel-callback" value="Cancel">Cancel-callback</option>
                                        <option key="Appt Visited" value="Appt Visited">Appt Visited</option>
                                        {/* <option key="Sold-Pending Install" value="Sold-Pending Install">Sold-Pending Install</option>
                                        <option key="Sold-Installed" value="Sold-Installed">Sold-Installed</option> */}
                                        <option key="To be Rescheduled" value="To be Rescheduled">To be Rescheduled</option>
                                        <option key="Customer Cancelled" value="Customer Cancelled">Customer Cancelled</option>
                                        <option key="Cancel" value="Cancel">Cancel</option>
                                        <option key="NA/VM - callback" value="NA/VM - callback">NA/VM - callback</option>
                                        <option key="DNC" value="DNC">DNC</option>
                                        <option key="Duplicate" value="Duplicate">Duplicate</option>
                                        <option key="CB - Scheduled" value="CB - Scheduled">CB - Scheduled</option>
                                        <option key="CB - Confirmed" value="CB - Confirmed">CB - Confirmed</option>
                                        <option key="QC - Confirmed" value="QC - Confirmed">QC - Confirmed</option>
                                        <option key="Over 80" value="Over 80">Over 80</option>
                                    </TextField>
                        }

                    </div>,
            },
            {
                name: 'Client Name',
                //selector: 'lead_status',
                sortable: true,
                center: true,
                omit: omitClinetView,
                cell: row =>
                    <div data-tag="allowRowEvents" style={{ background: row.client_name === 'CCHS' ? 'orange' : row.client_name === 'SCHS' ? 'skyblue' : row.client_name === 'SHS' ? 'violet' : row.client_name === 'POM' ? 'green' : row.client_name === 'SNG' ? 'yellow' : row.client_name === 'RAY' ? 'red'  : 'transparent' }}>
                        <TextField
                            id='whileFont'
                            name={row.id}
                            //name={row.id}
                            onChange={this.handleClientNameChange}
                            select
                            //label="Lead Status"
                            defaultValue={row.client_name}
                            SelectProps={{
                                native: true,
                            }}
                            fullWidth={true}
                            required
                        >
                            <option key="" value="">None</option>
                            {this.state.clientList.map(data => {
                                return (
                                    <option key={data.company_name} value={data.company_name}>{data.company_name}</option>
                                )
                            })}
                        </TextField>
                    </div>,
            },
            {
                name: 'Created On',
                sortable: true,
                cell: row =>
                    <div>
                        <span > {row.created_on}</span>
                    </div>
            },
            {
                name: 'Callback Confirmed',
                center: true,
                sortable: true,
                omit: omitIsConfirm,
                cell: row =>
                    <div data-tag="allowRowEvents">
                        {profileDetails.user_type === 'tl' ?
                            row.is_confirmed === '1' ? 'Yes' : 'No'
                            :
                            <FormControlLabel
                                size="small"
                                control={<IOSSwitch onChange={this.handleIsConfirmStatus} name={row.id} defaultChecked={row.is_confirmed === '1' ? 'true' : ''} />}
                            />
                        }
                    </div>,
            },
            // {
            //     name: 'View Recording',
            //     sortable: true,
            //     omit: omitRecording,
            //     cell: row => 
            //     <div style={{ fontWeight: 'bold' }}>
            //         <span leadId={row.lead_id} agentId={row.vici_agent_id} createdDate={row.created_on} onClick={this.getListOfRecordings} > View Recording</span>
            //     </div>

            // },
            {
                name: 'Call',
                sortable: true,
                omit: omitMakeCall,
                width: '3%',
                cell: row =>
                    <a target="_blank" href={`https://dialler.24sevensupportservices.com//agc/api.php?source=test&user=clz2u77&pass=fhft78IfAcc&agent_user=2s4s7102&function=external_dial&value=${row.cus_phone}&search=YES&preview=NO&focus=YES`} >
                        <CallIcon />
                    </a>
            },
            {
                name: 'Color',
                sortable: true,
                omit: omitColorView,
                width: '4%',
                cell: row =>
                    <span className="cityColorChange">
                        <Select
                            autoWidth
                            name={row.id}
                            //value={value}
                            onChange={this.handleChangeCityColor}
                            variant="filled"
                            classes={{
                                //root: classes.selectEmpty,
                                //select: classes.select
                            }}
                        >
                            <option className="cityColorOption" value='#ff0000'>Area 1</option>
                            <option className="cityColorOption" value='#e91e63c4'>Area 2</option>
                            <option className="cityColorOption" value='#ff9800'>Area 3</option>
                            <option className="cityColorOption" value='#e2cb00'>Area 4</option>
                            <option className="cityColorOption" value='#8bc34a'>Area 5</option>
                            <option className="cityColorOption" value='#9c27b0'>Area 9</option>
                            <option className="cityColorOption" value='#107ed5'>Area 7,8,10</option>
                            <option className="cityColorOption" value='#525252'>Area 11</option>
                        </Select>
                    </span>
            },


        ];


        const currentDate = new Date().toISOString().substring(0, 10);

        const toggleDrawer = (anchor, open) => (event) => {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            this.setState({ drawerOpen: false });
        };

        const handleSelectedRowChange = (state) => {
            //setSelectedData(state.selectedRows);
            this.setState({ selectedRows: state.selectedRows })
            console.log('selectedData', state.selectedRows);
        };

        const statusActionBtnMenus = ['Not Confirmed', 'Confirmed', 'Scheduled', 'Appt Visited', 'To be Rescheduled', 'Customer Cancelled', 'Cancel', 'NA/VM - callback', 'CB - Scheduled'];

        return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={isLoaderOpen} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div id="leadsPage">
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={this.state.snackbaropen}
                        onClose={this.handleClose}
                        autoHideDuration={4000}
                        key={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={this.handleClose} severity="success">
                            {this.state.alertMessage}
                        </Alert>
                    </Snackbar>
                    <Breadcrumbs className="headerBreadcrumb" aria-label="breadcrumb">
                        <Link color="inherit" href="/" >
                            Home
                        </Link>
                        <Typography color="textPrimary" >
                            Leads
                        </Typography>
                    </Breadcrumbs>
                    <div className="leadContent">
                        <Grid container spacing={3} className="headerBlock">
                            <Grid item xs={4}>
                                <div className="headerText">Leads</div>
                            </Grid>
                            <Grid item xs={7} style={{ textAlign: 'right' }}>
                                {profileDetails.user_type === 'superadmin' || profileDetails.user_type === 'manager' ?
                                    <div>
                                        <IconButton
                                            id="updateStatusActionBtn"
                                            size="small"
                                            aria-controls="basic-menu"
                                            aria-haspopup="true"
                                            aria-expanded={this.state.statusActionIsOpen ? 'true' : undefined}
                                            onClick={(event) => { this.setState({ statusActionPosition: event.currentTarget, statusActionIsOpen: !this.state.statusActionIsOpen }) }}
                                        >
                                            <div id="actionBtn">
                                                <span className="statusBtnText">Update Status</span>
                                                <span className="statusBtnIcon">
                                                    <ArrowDropDownCircleIcon size="small" sx={{ color: '#fff', marginLeft: '5px' }} />
                                                </span>
                                            </div>
                                        </IconButton>
                                        <Menu
                                            anchorEl={this.state.statusActionPosition}
                                            open={this.state.statusActionIsOpen}
                                            onClose={() => { this.setState({ statusActionIsOpen: !this.state.statusActionIsOpen }) }}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            {statusActionBtnMenus.map((menus, idx) => {
                                                return (
                                                    <MenuItem key={idx} value={menus} onClick={this.updateStatusRowLevel} >{menus}</MenuItem>
                                                )
                                            })}
                                        </Menu>
                                    </div>
                                    : ''}
                            </Grid>
                            <Grid item xs={1} className="addLeadBtn" >
                                {profileDetails.user_type === 'agent' || profileDetails.user_type === 'superadmin' || profileDetails.user_type === 'manager' ?
                                    <Button onClick={() => { this.setState({ addLeadModal: true }) }} variant="contained" color="primary">
                                        Add Lead
                                    </Button>
                                    : ''
                                }
                            </Grid>
                        </Grid>
                        <div className="leadTableViewSec">
                            <div className="tableHeaderBlock">
                                <Grid container spacing={3} className="headerBlock">
                                    <Grid item xs={6}>
                                        <TextField onChange={this.tableSearchInput} className="tableSearchInput" label="Search for leads" />
                                        <FormControlLabel
                                            className="enableAutoLoadLeadTable"
                                            size="small"
                                            label='Enable AutoLoad'
                                            control={<IOSSwitch onChange={this.handleAutoLoadTable} defaultChecked={this.state.autoLoadTable} />}
                                        />
                                        <span><TextField variant="standard" defaultValue={localStorage.getItem('autoLoadIntervalTime') && JSON.parse(localStorage.getItem('autoLoadIntervalTime'))} label={'Set AutoLoad Time'} onBlur={this.setTableLoadIntervalTime} /> <Button className="autoLoadSetBtn" onClick={this.setIntervalTimeBtn}>Set</Button></span>
                                    </Grid>
                                    <Grid item xs={6} className="FiltersLeadBtn" >
                                        {profileDetails.user_type === 'agent' ? '' :
                                            <div>
                                                {profileDetails.user_type === 'superadmin' || (profileDetails.centre_id === 'superadmin' && profileDetails.user_type === 'manager') ?
                                                    <Button variant="outlined" color="primary" className="exportExcel" onClick={this.ExportToExcel}>
                                                        Export To Excel
                                                    </Button> : ''
                                                }
                                                <Button variant="outlined" color="primary" className="applyFilterBtn" onClick={() => { this.setState({ drawerOpen: true }) }}>
                                                    Apply Filters
                                                </Button>
                                                <Button variant="outlined" color="primary" className="clearFilterBtn" onClick={this.clearFilters}>
                                                    Clear Filters
                                                </Button>

                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="tableBlock">
                                <DataTable
                                    //title="Arnold Movies"
                                    columns={columns}
                                    data={this.state.leadData}
                                    export={true}
                                    sortable={true}
                                    responsive={true}
                                    pagination={true}
                                    paginationServer={false}
                                    //expandableRowExpanded = {row => row.id === JSON.parse(localStorage.getItem('leadId')) ? JSON.parse(localStorage.getItem('leadId')) : ''}
                                    expandableIcon={{
                                        collapsed: <VisibilityOutlinedIcon />,
                                        expanded: <VisibilityOffOutlinedIcon />
                                    }}
                                    expandableRows={profileDetails.user_type === 'agent' || profileDetails.user_type === 'tl' ? false : true}
                                    expandOnRowClicked={profileDetails.user_type === 'agent' || profileDetails.user_type === 'tl' ? false : true}
                                    expandableRowsComponent={<ExpandedComponent getListOfLeads={this.handleFiltersLoadAuto} />}
                                    noHeader={true}
                                    paginationPerPage={25}
                                    selectableRows
                                    onSelectedRowsChange={handleSelectedRowChange}
                                    progressPending={this.state.tableLoading}
                                    progressComponent={<span>loading...</span>}
                                //selectableRowsComponent={Checkbox}Y
                                //selectableRowsComponentProps={{ inkDisabled: true }}
                                //sortIcon={<FontIcon>arrow_downward</FontIcon>}
                                //onSelectedRowsChange={}
                                />
                            </div>
                        </div>
                    </div>




                    <Drawer anchor='right' open={this.state.drawerOpen} onClose={toggleDrawer('right', false)}>
                        <div className="drawerRight" id="leadsFilers">
                            <div class="filterHeading">Filter Table By</div>
                            <form
                                noValidate
                                autoComplete="off"
                                onSubmit={this.handleFilters}
                            >
                                {profileDetails.user_type !== 'tl' && <TextField name="agent_no" onBlur={this.handleInputValue} id="agent_no" className="filterfields" fullWidth={true} label="Agent ID" variant="outlined"
                                    defaultValue={JSON.parse(localStorage.getItem('filterFieldDatas')) ? JSON.parse(localStorage.getItem('filterFieldDatas')).agent_no : ''}
                                />}
                                {profileDetails.user_type !== 'tl' && <TextField
                                    className="filterfields"
                                    id="lead_type"
                                    name="lead_type"
                                    select
                                    label="Lead Type"
                                    defaultValue={JSON.parse(localStorage.getItem('filterFieldDatas')) ? JSON.parse(localStorage.getItem('filterFieldDatas')).lead_type : ' '}
                                    SelectProps={{
                                        multiple: true,
                                        native: true,
                                        onChange: this.handleInputValue

                                    }}
                                    variant="outlined"
                                    fullWidth={true}
                                >
                                    <option key="" value=" " disabled selected></option>
                                    {/* <option key='Attic Insulation' value="Attic Insulation">Attic Insulation</option>
                                    <option key='HVAC' value="HVAC">HVAC</option> */}
                                    <option key='Virtual' value="Virtual">Virtual</option>
                                    <option key='Helium' value="Helium">Helium</option>
                                    <option key='Rebate' value="Rebate">Rebate</option>
                                    <option key='Check UP' value="Check UP">Check UP</option>
                                    <option key='Elderly' value="Elderly">Elderly</option>
                                </TextField>}
                                {profileDetails.user_type === 'tl' ? '' :
                                    <TextField
                                        className="filterfields"
                                        id="client_name"
                                        name="client_name"
                                        onChange={this.handleInputValue}
                                        select
                                        label="Client Name"
                                        defaultValue={JSON.parse(localStorage.getItem('filterFieldDatas')) ? JSON.parse(localStorage.getItem('filterFieldDatas')).client_name : ' '}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        variant="outlined"
                                        fullWidth={true}
                                    >
                                        <option key="" value=" " disabled selected></option>
                                        <option key="CCHS" value="CCHS">CCHS</option>
                                        <option key="SCHS" value="SCHS">SCHS</option>
                                        <option key="SHS" value="SHS">SHS</option>
                                        <option key="POM" value="POM">POM</option>
                                        <option key="SNG" value="SNG">SNG</option>
                                        <option key="RAY" value="RAY">RAY</option>
                                    </TextField>}

                                {profileDetails.user_type === 'superadmin' || profileDetails.user_type === 'manager'  ?
                                    <TextField
                                        className="filterfields"
                                        id="lead_status"
                                        name="lead_status"
                                        onChange={this.handleInputValue}
                                        select
                                        label="Lead Status"
                                        defaultValue={JSON.parse(localStorage.getItem('filterFieldDatas')) ? JSON.parse(localStorage.getItem('filterFieldDatas')).lead_status : ' '}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        variant="outlined"
                                        fullWidth={true}
                                    >
                                        <option key='0' value=" " disabled selected></option>
                                        <option key="Not Confirmed" value="Not Confirmed">Not Confirmed</option>
                                        <option key="Confirmed" value="Confirmed">Confirmed</option>
                                        <option key="Scheduled" value="Scheduled">Scheduled</option>
                                        <option key="Appt Visited" value="Appt Visited">Appt Visited</option>
                                        {/* <option key="Sold-Pending Install" value="Sold-Pending Install">Sold-Pending Install</option>
                                        <option key="Sold-Installed" value="Sold-Installed">Sold-Installed</option> */}
                                        <option key="To be Rescheduled" value="To be Rescheduled">To be Rescheduled</option>
                                        <option key="Customer Cancelled" value="Customer Cancelled">Customer Cancelled</option>
                                        <option key="Cancel" value="Cancel">Cancel</option>
                                        <option key="NA/VM - callback" value="NA/VM - callback">NA/VM - callback</option>
                                        <option key="DNC" value="DNC">DNC</option>
                                        <option key="Duplicate" value="Duplicate">Duplicate</option>
                                        <option key="CB - Scheduled" value="CB - Scheduled">CB - Scheduled</option>
                                        <option key="CB - Confirmed" value="CB - Confirmed">CB - Confirmed</option>
                                        <option key="QC - Confirmed" value="QC - Confirmed">QC - Confirmed</option>
                                        <option key="Over 80" value="Over 80">Over 80</option>
                                    </TextField>
                                    : profileDetails.user_type === 'callback' ?
                                    <TextField
                                        className="filterfields"
                                        id="lead_status"
                                        name="lead_status"
                                        onChange={this.handleInputValue}
                                        select
                                        label="Lead Status"
                                        defaultValue={JSON.parse(localStorage.getItem('filterFieldDatas')) ? JSON.parse(localStorage.getItem('filterFieldDatas')).lead_status : ' '}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        variant="outlined"
                                        fullWidth={true}
                                    >
                                        <option key='0' value=" " disabled selected></option>
                                        <option key="Not Confirmed" value="Not Confirmed">Not Confirmed</option>
                                        <option key="Confirmed" value="Confirmed">Confirmed</option>
                                        <option key="Scheduled" value="Scheduled">Scheduled</option>
                                        <option key="Appt Visited" value="Appt Visited">Appt Visited</option>
                                        <option key="To be Rescheduled" value="To be Rescheduled">To be Rescheduled</option>
                                        <option key="Customer Cancelled" value="Customer Cancelled">Customer Cancelled</option>
                                        <option key="Cancel" value="Cancel">Cancel</option>
                                        <option key="NA/VM - callback" value="NA/VM - callback">NA/VM - callback</option>
                                        <option key="DNC" value="DNC">DNC</option>
                                        <option key="Duplicate" value="Duplicate">Duplicate</option>
                                        <option key="CB - Scheduled" value="CB - Scheduled">CB - Scheduled</option>
                                        <option key="CB - Confirmed" value="CB - Confirmed">CB - Confirmed</option>
                                        <option key="QC - Confirmed" value="QC - Confirmed">QC - Confirmed</option>
                                        <option key="Over 80" value="Over 80">Over 80</option>
                                    </TextField>
                                    : <TextField
                                        className="filterfields"
                                        id="lead_status"
                                        name="lead_status"
                                        onChange={this.handleInputValue}
                                        select
                                        label="Lead Status"
                                        defaultValue={JSON.parse(localStorage.getItem('filterFieldDatas')) ? JSON.parse(localStorage.getItem('filterFieldDatas')).lead_status : ' '}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        variant="outlined"
                                        fullWidth={true}
                                    >
                                        <option key='0' value=" " disabled selected></option>
                                        <option key="Scheduled" value="Scheduled">Scheduled</option>
                                        <option key="Appt Visited" value="Appt Visited">Appt Visited</option>
                                        <option key="Cancel" value="Cancel">Cancel</option>
                                        <option key="NA/VM - callback" value="NA/VM - callback">NA/VM - callback</option>
                                        <option key="DNC" value="DNC">DNC</option>
                                        <option key="Duplicate" value="Duplicate">Duplicate</option>
                                        <option key="Over 80" value="Over 80">Over 80</option>
                                    </TextField>
                                }
                                <TextField
                                    className="filterfields"
                                    id="appt_time"
                                    name="appt_time"
                                    onBlur={this.handleInputValue}
                                    select
                                    label="Appointment Time"
                                    SelectProps={{
                                        native: true,
                                    }}
                                    defaultValue={JSON.parse(localStorage.getItem('filterFieldDatas')) ? JSON.parse(localStorage.getItem('filterFieldDatas')).appt_time : ' '}
                                    variant="outlined"
                                    fullWidth={true}
                                >
                                    <option key='0' value=" " disabled selected></option>
                                    <option key='1' value="9am - 11am">9am - 11am</option>
                                    <option key='2' value="10am - 12am">10am - 12am</option>
                                    <option key='3' value="11am - 1pm">11am - 1pm</option>
                                    <option key='4' value="12am - 2pm">12am - 2pm</option>
                                    <option key='5' value="1pm - 3pm">1pm - 3pm</option>
                                    <option key='6' value="2pm - 4pm">2pm - 4pm</option>
                                    <option key='7' value="3pm - 5pm">3pm - 5pm</option>
                                    <option key='8' value="4pm - 6pm">4pm - 6pm</option>
                                    <option key='9' value="5pm - 7pm">5pm - 7pm</option>
                                    <option key='10' value="6pm - 8pm">6pm - 8pm</option>
                                    <option key='11' value="7pm - 9pm">7pm - 9pm</option>
                                </TextField>
                                <TextField
                                    name="from_date"
                                    id="from_date"
                                    label="From Date"
                                    type="date"
                                    fullWidth={true}
                                    className="filterfields"
                                    defaultValue={JSON.parse(localStorage.getItem('filterFieldDatas')) ? JSON.parse(localStorage.getItem('filterFieldDatas')).from_date : currentDate}
                                    onBlur={this.handleInputValue}
                                    //className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    name="to_date"
                                    id="to_date"
                                    label="To Date"
                                    type="date"
                                    fullWidth={true}
                                    className="filterfields"
                                    defaultValue={JSON.parse(localStorage.getItem('filterFieldDatas')) ? JSON.parse(localStorage.getItem('filterFieldDatas')).to_date : currentDate}
                                    onBlur={this.handleInputValue}
                                    //className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <Button variant="outlined" fullWidth={true} className="filterApplyBtn" type="submit" color="primary">
                                    Apply Filters
                                </Button>
                            </form>
                        </div>
                    </Drawer>




                    <Dialog
                        fullWidth={true}
                        maxWidth={'md'}
                        open={this.state.addLeadModal}
                        onClose={this.handleAddLeadClose}
                        aria-labelledby="max-width-dialog-title"
                        className="addNewLeadDialogBlock"
                    >
                        <form
                            autoComplete="off"
                            onSubmit={this.submitLead.bind(this)}
                        >
                            <DialogTitle id="max-width-dialog-title" className="addNewLeadModalHeader">
                                <Grid container spacing={3}>
                                    <Grid item xs={6} className="addLeadModalHeaderTitle">
                                        Add New Lead
                                    </Grid>
                                    <Grid item xs={6} className="addLeadModalBtnsBlock">
                                        <Button variant="outlined" className="cancelBtn" onClick={() => { this.setState({ addLeadModal: false }) }} color="primary">
                                            Cancel
                                        </Button>
                                        <Button variant="outlined" className="saveBtn" type="submit" color="primary">
                                            {this.state.btnLoading && <CircularProgress disableShrink={true} size={20} />}
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent className="addNewLeadModal">
                                <Grid container spacing={3}>
                                    <Grid xs={12} style={{ marginTop: "12px" }}>
                                        <h4 className="formBlockHeader">Appointment Details</h4>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            name="appt_date"
                                            onBlur={this.handleAddLeadInput}
                                            label="Appointment Date"
                                            type="date"
                                            fullWidth={true}
                                            variant="outlined"
                                            defaultValue={currentDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-select-currency-native"
                                            name="appt_time"
                                            onBlur={this.handleAddLeadInput}
                                            select
                                            label="Appointment Time"
                                            SelectProps={{
                                                native: true,
                                            }}
                                            variant="outlined"
                                            fullWidth={true}
                                            required
                                        >
                                            <option key='1' value="9am - 11am">9am - 11am</option>
                                            <option key='2' value="10am - 12am">10am - 12am</option>
                                            <option key='3' value="11am - 1pm">11am - 1pm</option>
                                            <option key='4' value="12am - 2pm">12am - 2pm</option>
                                            <option key='5' value="1pm - 3pm">1pm - 3pm</option>
                                            <option key='6' value="2pm - 4pm">2pm - 4pm</option>
                                            <option key='7' value="3pm - 5pm">3pm - 5pm</option>
                                            <option key='8' value="4pm - 6pm">4pm - 6pm</option>
                                            <option key='9' value="5pm - 7pm">5pm - 7pm</option>
                                            <option key='10' value="6pm - 8pm">6pm - 8pm</option>
                                            <option key='11' value="7pm - 9pm">7pm - 9pm</option>
                                        </TextField>
                                    </Grid>
                                    <Grid xs={12}>
                                        <h4 className="formBlockHeader">Lead Details</h4>
                                    </Grid>
                                    <Grid item xs={6} spacing={0}>
                                        <TextField
                                            id="outlined-select-currency-native"
                                            name="lead_type"
                                            onBlur={this.handleAddLeadInput}
                                            select
                                            label="Lead Type"
                                            defaultValue=" "
                                            SelectProps={{
                                                native: true,
                                            }}
                                            variant="outlined"
                                            fullWidth={true}
                                            required
                                        >
                                            <option key="" value=" " selected disabled>None</option>
                                            {/* <option key='Attic Insulation' value="Attic Insulation">Attic Insulation</option>
                                            <option key='HVAC' value="HVAC">HVAC</option> */}
                                            <option key='Virtual' value="Virtual">Virtual</option>
                                            <option key='Helium' value="Helium">Helium</option>
                                            <option key='Rebate' value="Rebate">Rebate</option>
                                            <option key='Check UP' value="Check UP">Check UP</option>
                                            <option key='Elderly' value="Elderly">Elderly</option>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-select-currency-native"
                                            name="lead_status"
                                            onBlur={this.handleAddLeadInput}
                                            select
                                            label="Status"
                                            SelectProps={{
                                                native: true,
                                            }}
                                            variant="outlined"
                                            fullWidth={true}
                                            required
                                        >
                                            <option key="Not Confirmed" value="Not Confirmed">Not Confirmed</option>
                                            <option key="Confirmed" value="Confirmed">Confirmed</option>
                                            <option key="Scheduled" value="Scheduled">Scheduled</option>
                                            <option key="Cancel-callback" value="Cancel">Cancel-callback</option>
                                            <option key="Appt Visited" value="Appt Visited">Appt Visited</option>
                                            {/* <option key="Sold-Pending Install" value="Sold-Pending Install">Sold-Pending Install</option>
                                            <option key="Sold-Installed" value="Sold-Installed">Sold-Installed</option> */}
                                            <option key="To be Rescheduled" value="To be Rescheduled">To be Rescheduled</option>
                                            <option key="Customer Cancelled" value="Customer Cancelled">Customer Cancelled</option>
                                            <option key="Cancel" value="Cancel">Cancel</option>
                                            <option key="No Answer-callback" value="No Answer">No Answer-callback</option>
                                            <option key="Voice Mail-callback" value="Voice Mail">Voice Mail-callback</option>
                                        </TextField>
                                    </Grid>
                                    <Grid xs={12}>
                                        <h4 className="formBlockHeader">Customer Details</h4>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField name="cust_name" onBlur={this.handleAddLeadInput} fullWidth={true} label="Customer Name" variant="outlined" required />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField name="cust_phone" onBlur={this.handleAddLeadInput} fullWidth={true} label="Customer Phone" variant="outlined" required />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField name="cust_mobile" onBlur={this.handleAddLeadInput} fullWidth={true} label="Customer Mobile" variant="outlined" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField name="cust_email" onBlur={this.handleAddLeadInput} fullWidth={true} label="Customer Email" variant="outlined" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-textarea"
                                            name="cust_address"
                                            onBlur={this.handleAddLeadInput}
                                            label="Customer Address"
                                            placeholder="Customer Address"
                                            multiline
                                            fullWidth={true}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-textarea"
                                            name="lead_notes"
                                            onBlur={this.handleAddLeadInput}
                                            label="Lead Notes"
                                            placeholder="Lead Notes"
                                            multiline
                                            fullWidth={true}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </form>
                    </Dialog>

                    <Dialog
                        fullWidth={true}
                        maxWidth={'sm'}
                        open={this.state.recordingModal}
                        onClose={this.handleAddLeadClose}
                        aria-labelledby="max-width-dialog-title"
                        className="addNewLeadDialogBlock"
                    >
                        <DialogTitle id="max-width-dialog-title" className="addNewLeadModalHeader">
                            <Grid container spacing={3}>
                                <Grid item xs={6} className="addLeadModalHeaderTitle">
                                    Recording
                                </Grid>
                                <Grid item xs={6} className="addLeadModalBtnsBlock">
                                    <Button variant="outlined" className="cancelBtn" onClick={() => { this.setState({ recordingModal: false }) }} color="primary">
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent className="addNewLeadModal">
                            <Grid container spacing={3}>
                                <div id="recodingView">
                                    <div style={{ marginTop: '3%' }}><span style={{ fontWeight: 'bold' }}>Agent ID: </span>  {this.state.recordingDet[1]}</div>
                                    <div style={{ marginTop: '3%' }}><span style={{ fontWeight: 'bold' }}>Lead ID: </span>   {this.state.recordingDet[3]}</div>
                                    <div style={{ marginTop: '3%' }}><span style={{ fontWeight: 'bold' }}>Created On: </span>{this.state.recordingDet[0]}</div>
                                    <audio
                                        style={{ marginTop: '10%' }}
                                        ref="audio_tag"
                                        autoPlay={true}
                                        controls={true} controlsList="nodownload" >
                                        <source type="audio/mp3" src={this.state.recordingUrl} />
                                    </audio>
                                </div>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </div>
                {/* <div className="signiture">
                        <h1>Template Built & Designed by Roman Chvalbo</h1>
                    </div> */}
            </React.Fragment>
        );
    }

}

export default Leads;