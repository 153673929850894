import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */
import AuthHelperMethods from '../../components/AuthHelperMethods';
import Donut from "./Donut";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
const dotenv = require("dotenv");
dotenv.config({ path: ".env" });

class Dashboard extends Component {
    Auth = new AuthHelperMethods();
    constructor(props) {
		super(props);
		this.state= {
			countsData: {},
            isLoaderOpen: true,
            vicidialPanel: false,
            random: 0,
            profileDetails: JSON.parse(localStorage.getItem('user')),
            apiUrl: process.env.REACT_APP_API_URL,
            apiKey: 'da731be431a0e7d95f01f98888ac94c1'
		}
        const userDetails = JSON.parse(localStorage.getItem('user'));
        this.getcountsOfLeads(userDetails);
	}
	
	async componentDidMount(){
		
	}
    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */
   

getcountsOfLeads = async(userDetails) => {
    this.setState({ tableLoading: true });
    const centreId = userDetails.centre_id;
    const requestType = 'getDashboardCounts';
    const apiKey = this.state.apiKey;
    fetch(`${this.state.apiUrl}/dashboard/counts.php`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            centreId, requestType, apiKey
        })
    })
    .then(res => res.json())
    .then(json => {
        this.setState({
            countsData: json.data,
            isLoaderOpen: false
        });
    })
}
  

_handleLogout = () => {
    this.Auth.logout()
    this.props.history.replace('/login');
}
                

    

viewViciDialPanel = () => {
    var vicidialPanel = this.state.vicidialPanel;
    if(vicidialPanel === true) {
        this.setState({vicidialPanel: false});
    }else{
        this.setState({vicidialPanel: true});
    }
    this.setState({random: this.state.random + 1});
}
                


render() {
    const { countsData, isLoaderOpen, vicidialPanel, profileDetails } = this.state;
    
    
    
return (
<React.Fragment>
    <Backdrop className="backDropLoader" open={isLoaderOpen} >
        <CircularProgress color="inherit" />
    </Backdrop>
    <div id="dashboardPage">
            {/* asdfasdf
        <button onClick={this._handleLogout}>LOGOUT</button> */}
        <Breadcrumbs className="headerBreadcrumb" aria-label="breadcrumb">
            <Typography color="textPrimary" >
                Dashboard
            </Typography>
        </Breadcrumbs>
        {profileDetails.user_type === 'superadmin' || profileDetails.user_type === 'manager' ?
            <div className="countSection">
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <h3>Total Appointments Today</h3>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <div className="countBlocks">
                                <span>Confirmed</span>
                                {/* <GroupIcon /> */}
                                <span>{countsData.todayAppMadeConfirmed}</span>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="countBlocks">
                                <span>Total Leads</span>
                                <span>{countsData.todayAppMade}</span>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="countBlocks">
                                <span>Customer Cancelled-callback</span>
                                <span>{countsData.todayAppMadeCancelled}</span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <h3>Appointment Time Today</h3>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <div className="countBlocks">
                                <span>Morning</span>
                                {/* <GroupIcon /> */}
                                <span>{countsData.todayTimeLeadMorning}</span>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="countBlocks">
                                <span>Afternoon</span>
                                <span>{countsData.todayTimeLeadAfternoon}</span>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="countBlocks">
                                <span>Evening</span>
                                <span>{countsData.todayTimeLeadEveing}</span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>
        </div>
        : '' }
        {/* <div className="countSection">
            <h3>Total Appointments Today</h3>
            <Grid container spacing={3}>
                <Grid item xs={2}>
                    <div className="countBlocks">
                        <span>Today Appt's Made</span>
                        <span>{countsData.todayAppMade}</span>
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div className="countBlocks">
                        <span>Total Leads</span>
                        <span>{countsData.totalLeads}</span>
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div className="countBlocks">
                        <span>Total Users</span>
                        <span>{countsData.totalUsers}</span>
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div className="countBlocks">
                        <span>Total Centres</span>
                        <span>{countsData.totalCentres}</span>
                    </div>
                </Grid>
            </Grid>
        </div> */}
        <Button onClick={this.viewViciDialPanel}
            style={{
                marginTop: '1%',
                background: '#4f92ff',
                color: '#fff'
            }}
        >View RealTime Panel</Button>
        {vicidialPanel ? 
        <iframe 
            key={this.state.random}
            src="https://webdial.24sevensupportservices.com/vicidial/realtime_report.php?report_display_type=HTML#"
            style={{
                width: '100%',
                height: '700px',
                pointerEvents: 'none', marginTop: '5px'}}
        > 
        </iframe>: '' }

    </div>
</React.Fragment>
);
}

}

export default Dashboard;