import React, { Component } from "react";

class RowComponent extends Component {
    render() {
        var UserDet = this.props.managerDetails;
    return (
    <React.Fragment>
        <div>
        {UserDet.map((data, idx) => {
            return(
                <span>{data.first_name} {data.last_name}, </span>
            )
        })}
        </div>
    </React.Fragment>
      );
    }
}
export default RowComponent;