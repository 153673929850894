import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */
//import AuthHelperMethods from '../../components/AuthHelperMethods';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
//import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DataTable from 'react-data-table-component';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import RowComponent from './RowComponent';
const dotenv = require("dotenv");
dotenv.config({ path: ".env" });

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class Centres extends Component {
    constructor(props) {
		super(props);
		this.state= {
            centresList:[],
            addCentreDet: {},
            snackbaropen: false,
            alertMessage: '',
            alertMessageType: '',
            tableLoading: true,
            addCentreModal: false,
            btnLoading: false,
            apiUrl: process.env.REACT_APP_API_URL
            
		}
        const userDetails = JSON.parse(localStorage.getItem('user'));
        this.getListOfCentres(userDetails);
        //console.log(Object.keys(this.state.filterFieldDatas).length);
        //console.log(this.props.match.params.id);
	}
	
async componentDidMount(){

}

getListOfCentres = async(userDetails) => {
    this.setState({ tableLoading: true });
    const centreId = userDetails.centre_id;
    const requestType = 'listOfCentres';
    fetch(`${this.state.apiUrl}/centre/listCentres.php`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            centreId,
            requestType
        })
    })
    .then(res => res.json())
    .then(json => {
        this.setState({
            centresList: json.data,
            tableLoading: false,
            alertMessage: 'Lead data is loaded successfully!',
            alertMessageType: 'success',
            snackbaropen: true
        });
    })
}


handleNewCentreValue = (e) => {
    let val = e.target.value;
    var name = e.target.name;
    var obj = {...this.state.addCentreDet};
    obj[name] = val;
    this.setState({ addCentreDet: obj })
}



_handleLogout = () => {
    this.Auth.logout()
    this.props.history.replace('/login');
}

                
handleClose = () => {
    this.setState({snackbaropen: false});
};

onboardNewCentre = event => {
    event.preventDefault();
        this.setState({btnLoading:true,newUserError: ''});
        const userDetails = JSON.parse(localStorage.getItem('user'));
        const centreId = userDetails.centre_id;
        const addedCentresInputValues = this.state.addCentreDet;
        const requestType = 'onBoardNewCentre';
        fetch(`${this.state.apiUrl}/centre/addCentres.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                addedCentresInputValues,
                centreId,
                requestType
            })
        })
        .then(res => res.json())
        .then(json => {
            console.log(json.status);
            if(json.status === 'error'){
                this.setState({
                    btnLoading:false,
                    alertMessageType:'warning',
                    alertMessage: json.message,
                    snackbaropen: true,
                });
            }else{
                const userDetails = JSON.parse(localStorage.getItem('user'));
                this.getListOfCentres(userDetails);
                this.setState({
                    btnLoading:false,
                    addCentreModal: false,
                    alertMessageType: 'success',
                    alertMessage: 'New Centre is Onboarded successfully!',
                    snackbaropen: true
                });
            }
            
        })
        
};




render() {

    const IOSSwitch = withStyles((theme) => ({
        root: {
          width: 42,
          height: 26,
          padding: 0,
          margin: theme.spacing(1),
        },
        switchBase: {
          padding: 1,
          '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
              backgroundColor: '#52d869',
              opacity: 1,
              border: 'none',
            },
          },
          '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 26 / 2,
          border: `1px solid ${theme.palette.grey[400]}`,
          backgroundColor: theme.palette.grey[50],
          opacity: 1,
          transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    }))(({ classes, ...props }) => {
        return (
          <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            {...props}
          />
        );
        });


      const handleIsConfirmed = (event) => {
        console.log(event.target.checked);
      };

        
    const columns = [
        {
            name: 'Centre Name',
            //selector: 'appt_date',
            sortable: true,
            width: '13%',
            cell: row => 
            <span>{row.centre_name}</span>,
        },
        {
            name: 'Location',
            selector: 'location',
            sortable: true,
        },
        {
            name: 'Centre Owner',
            selector: 'centre_owner',
            sortable: true,
            
        },
        {
            name: 'Service',
            selector: 'service',
            sortable: true,
        },
        {
            name: 'No of Agents',
            selector: 'noof_agents',
            sortable: true,
            cell: row => 
            <RowComponent centreId = {row.centre_id} />
        },
        {
            name: 'App Met',
            selector: 'app_met',
            sortable: true,
        },
        {
            name: 'Sold Installed',
            selector: 'sold_installed',
            sortable: true,
        },
        {
            name: 'Mode of Payment',
            selector: 'mode_of_payment',
            sortable: true,
        },
        {
            name: 'Payment Details',
            selector: 'payment_gateway_details',
            sortable: true,
        },
        {
            name: 'Created On',
            selector: 'created_on',
            sortable: true,
        },
    ];
    

    const currentDate = new Date().toISOString().substring(0, 10);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        this.setState({ drawerOpen: false });
    };

    
return (
    <React.Fragment>
        <div id="leadsPage">
            <Snackbar
                anchorOrigin={{  vertical: 'top', horizontal: 'right' }}
                open={this.state.snackbaropen}
                onClose={this.handleClose}
                autoHideDuration={4000}
                key={{vertical: 'top', horizontal: 'right'}}
            >
                <Alert onClose={this.handleClose} severity={this.state.alertMessageType}>
                    {this.state.alertMessage}
                </Alert>
            </Snackbar>
            <Breadcrumbs className="headerBreadcrumb" aria-label="breadcrumb">
                <Link color="inherit" href="/" >
                    Home
                </Link>
                <Typography color="textPrimary" >
                    Centres
                </Typography>
            </Breadcrumbs>
            <div className="leadContent">
                <Grid container spacing={3} className="headerBlock">
                    <Grid item xs={6}>
                        <div className="headerText">Manage Centres</div>
                    </Grid>
                    <Grid item xs={6} className="addLeadBtn" >
                        {/* <Button onClick={() => { this.setState({addCentreModal: true}) }} variant="contained" color="primary">
                            Add New User
                        </Button> */}
                    </Grid>
                </Grid>

                <div className="leadTableViewSec">
                    <div className="tableHeaderBlock">
                        <Grid container spacing={3} className="headerBlock">
                            <Grid item xs={6}>
                                <TextField onChange={this.tableSearchInput} className="tableSearchInput" label="Search for Centres" />
                            </Grid>
                            <Grid item xs={6} className="addLeadBtn" >
                                <Button onClick={() => { this.setState({addCentreModal: true}) }} variant="contained" color="primary">
                                    Add New Centre
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="tableBlock">
                        <DataTable
                            //title="Arnold Movies"
                            columns={columns}
                            data={this.state.centresList}
                            sortable = {true}
                            responsive = {true}
                            pagination = {true}
                            paginationServer = {false}
                            //fixedHeader = {true}
                            expandableIcon={{
                                collapsed: <VisibilityOutlinedIcon />,
                                expanded: <VisibilityOffOutlinedIcon />
                                }}
                            noHeader={true}
                            //selectableRows
                            progressPending={this.state.tableLoading}
                            progressComponent={<span>loading...</span>}
                            //selectableRowsComponent={Checkbox}
                            selectableRowsComponentProps={{ inkDisabled: true }}
                            //sortIcon={<FontIcon>arrow_downward</FontIcon>}
                            //onSelectedRowsChange={}
                        />
                    </div>
                </div>
            </div>

<Dialog
    fullWidth={true}
    maxWidth={'md'}
    open={this.state.addCentreModal}
    onClose={this.handleAddLeadClose}
    aria-labelledby="max-width-dialog-title"
    className="addNewLeadDialogBlock"
>
    <form 
        autoComplete="off" 
        onSubmit={this.onboardNewCentre.bind(this)}
    >
        <DialogTitle id="max-width-dialog-title" className="addNewLeadModalHeader">
            <Grid container spacing={3}>
                <Grid item xs={6} className="addLeadModalHeaderTitle">
                    Onboard New Centre
                </Grid>
                <Grid item xs={6} className="addLeadModalBtnsBlock">
                    <Button variant="outlined" className="cancelBtn" onClick={() => { this.setState({addCentreModal: false}) }} color="primary">
                        Cancel
                    </Button>
                    <Button variant="outlined" className="saveBtn" type="submit" color="primary" disabled={this.state.newUserSaveBtn}>
                        {this.state.btnLoading && <CircularProgress disableShrink={true} size={20}/>}
                        Onboard
                    </Button>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent className="addNewLeadModal">
            {this.state.newUserError}
            <Grid container spacing={3}>
                <Grid xs={12} style={{marginTop: "12px"}}>
                    <h4 className="formBlockHeader">Centre Details</h4>
                </Grid>
                <Grid item xs={6}>
                    <TextField name="centre_name" onBlur={this.handleNewCentreValue} fullWidth={true} label="Centre Name" variant="outlined" required />
                </Grid>
                <Grid item xs={6}>
                    <TextField name="location" onBlur={this.handleNewCentreValue} fullWidth={true} label="Location" variant="outlined" required />
                </Grid>
                <Grid item xs={6}>
                    <TextField name="centre_owner" onBlur={this.handleNewCentreValue} fullWidth={true} label="Owner Name" variant="outlined" required />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name="service"
                        onBlur={this.handleNewCentreValue}
                        select
                        label="Service"
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                        fullWidth={true} 
                        defaultValue='none'
                        required
                    >
                        <option value='none'>Select Service Type</option>
                        <option value="Voice">Voice</option>
                        <option value="Avatar">Avatar</option>
                        <option value="Voice and Avatar">Voice and Avatar</option>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField name="app_met" onBlur={this.handleNewCentreValue} fullWidth={true} label="App Met" variant="outlined" required />
                </Grid>
                <Grid item xs={6}>
                    <TextField name="sold_installed" onBlur={this.handleNewCentreValue} fullWidth={true} label="Sold/Installed ($)" variant="outlined" required />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name="mode_of_payment"
                        onBlur={this.handleNewCentreValue}
                        select
                        label="Payment Gateway"
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                        fullWidth={true} 
                        defaultValue='none'
                        required
                    >
                        <option value='none'>Select Payment Gateway</option>
                        <option value="Paypal + 4%">Paypal + 4%</option>
                        <option value="Paypal">Paypal</option>
                        <option value="Bank Transfer">Bank Transfer</option>
                        <option value="Card Payment">Card Payment</option>
                        <option value="Payoneer">Payoneer</option>
                        <option value="Skrill">Skrill</option>
                        <option value="Stripe">Stripe</option>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField name="payment_gateway_details" multiline rows={5} onBlur={this.handleNewCentreValue} fullWidth={true} label="Payment Details" variant="outlined" />
                </Grid>
                
               
            </Grid>
        </DialogContent>
    </form>
</Dialog>

        </div>
                       {/* <div className="signiture">
                        <h1>Template Built & Designed by Roman Chvalbo</h1>
                    </div> */}
            </React.Fragment>
        );
    }

}

export default Centres;